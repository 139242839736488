<template>
    <div class="footer-container">
        <router-link to="/privacy-policy" class="card" rel="noopener noreferrer">
            <h2>Privacy policy <span>-></span></h2>
            <p>Read our privacy policy</p>
        </router-link>
        <router-link to="/terms-and-conditions" class="card" rel="noopener noreferrer">
            <h2>Terms of use <span>-></span></h2>
            <p>Read our terms of use</p>
        </router-link>
        <a class="card" href="mailto:office@ipagopay.com" rel="noopener noreferrer">
            <h2>Contact <span>-></span></h2>
            <p>Write to us</p>
        </a>
        <span class="card-company">
            <span class="card-company-name">
                iPago Payments Inc.
            </span>
            <span class="card-company-email">
                <a href="mailto:contact@ipagopay.com">contact@ipagopay.com</a>
            </span>
            <span class="card-company-address">
                <p>1500 West Georgia, 13th floor.</p>
                <p>Vancouver, V6G 2Z6</p>
            </span>
        </span>
    </div>
</template>

<style lang="scss">
.footer-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(25%, auto));
    width: var(--max-width);
    max-width: 100%;
}

.card-company {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;

    &>*+* {
        margin-top: 5px;
    }

    &>.card-company-email {
        font-size: 14px;
        font-weight: 600;
        opacity: 0.6;
    }

    &>.card-company-name {
        font-weight: 700;
        font-size: 18px;
    }

    &>.card-company-address {
        font-size: 13px;
    }
}

.card {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;

    & span {
        display: inline-block;
        transition: transform 200ms;
    }

    & h2 {
        font-weight: 600;
        margin-bottom: 0.7rem;
        font-family: "Inter";
    }

    & p {
        margin: 0;
        opacity: 0.6;
        font-size: 0.9rem;
        line-height: 1.5;
        max-width: 30ch;
    }

    &:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);

        & span {
            transform: translateX(4px);
        }
    }
}

@media (max-width: 700px) {
    .footer-container {
        grid-template-columns: 1fr;
        margin-bottom: 120px;
        max-width: 320px;
        text-align: center;

        & > .card-company {
            width: 100%;
            margin: 50px 0px 0px 0px;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }

    .card {
        padding: 1rem 2.5rem;
    }

    .card h2 {
        margin-bottom: 0.5rem;
    }
}

</style>

<script>
export default {
    name: 'FooterLayoutComponent'
}
</script>