<template>
  <Watermark></Watermark>
  <div class="document">
    <span class="document-title">Privacy policy</span>
    <div class="document-section">
      <span class="document-section-title">Initial statements</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            The goal of this privacy policy is to provide you with the information regarding how IPAGO PAYMENTS INC
            collects and processes your personal data, when using our websites or payment platforms (hereinafter
            referred to as "Platforms"). It includes all personal data you provide IPAGO PAYMENTS INC while using
            this website, including when you provide your personal data in our contact form which helps IPago
            contact you to describe and explain the services we provide.
          </li>
          <li>
            In this Privacy Policy, the terms "iPago", "us" or "ours" (and similar) describe the IPAGO PAYMENTS INC
            with
            its seat in 1500 West Georgia BC, Vancouver, V6G 2Z6 Canada, therefore the entity responsible for
            processing
            your personal data.
          </li>
          <li>
            We ask you to read this Privacy Policy along with the additional information regarding IPago services.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Collection of Personal Information</span>
      <div class="document-section-content">
        <ul class="document-section-empty">
          <li>
            Personal information is any information that can be used to identify an individual, and includes, but is not
            limited to, a person's name, address, telephone number, email address, and credit card information. Personal
            information also includes any other information that is associated with or linked to any of the above
            information.
          </li>
          <li>
            We may collect personal information from users in a variety of ways, including, but not limited to, when
            users
            visit our website, register on the website, place an order, subscribe to a newsletter, respond to a survey,
            fill out a form, and in connection with other activities, services, features, or resources we make available
            on our website. Users may be asked for, as appropriate, name, email address, mailing address, phone number,
            credit card information, and personal information.
          </li>
          <li>
            "Personal Data" is a term describing all information of an identified or identifiable individual. Depending
            on
            who you are (e.g. an acceptor, a client, an owner of a credit card, a consumer, a provider or a business
            partner) and how you contact us (e.g. by phone, online or offline), we can collect, use, receive, store,
            analyse, connect, hand down or process in a different way various categories of personal data.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Use of Personal Information</span>
      <div class="document-section-content">
        <p>We may use personal information to:</p>

        <ul class="document-section-points">
          <li>
            Improve customer service
          </li>
          <li>
            Personalize user experience
          </li>
          <li>
            Improve our website
          </li>
          <li>
            To process payment transactions made through the usage of our Platforms.
          </li>
          <li>
            Send periodic emails
          </li>
          <li>
            Follow up with them after correspondence (live chat, email or phone inquiries
          </li>
          <li>
            To conduct "Know your customer" and risk assessment procedures, to confirm and authorize your usage of
            our services, depending on whether you are an acceptor or a client as well as depending on chosen
            products / services. The necessary personal data usually contains information regarding identity,
            contact information and financial information. They are necessary for iPago to rate your application for
            the usage of our services and products based on the agreement you made with us, as well as for
            fulfilling our legal obligations.
          </li>
          <li>
            To conduct research, continue to develop and improve our products and services.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Personal data, processed for marketing purposes </span>
      <div class="document-section-content">
        <p>You might receive marketing information from iPago, if:</p>
        <ul class="document-section-points">
          <li>
            you asked us for more information;
          </li>
          <li>
            you have given us your contact information to receive content and announcements, including research
            studies, conclusions or researches being conducted;
          </li>
          <li>
            you subscribed to services or products from Us; or
          </li>
          <li>
            you took part in a marketing campaign, an offer, poll or a loyalty program and you provided your contact
            information for the purpose of participating in such activity. We can conduct such marketing activities
            directly or through promotional agencies.
          </li>
        </ul>
        <ul class="document-section-empty">
          <li>erforming such marketing activities is subject to laws of the country in which marketing activites and
            announcements are conducted. We keep a personal data register which we use for marketing and communication
            purposes. You can resign from receiving marketing information by clicking the link regarding resignation or
            cancelling of a subscription, which can be found in the contents of such marketing notifications.
          </li>
          <li>
            Depending on the regulations of a country in which activity is conducted, you might be asked for your
            agreement to receive marketing notifications from Us.
          </li>
          <li>
            We can also use marketing and communication data to improve and adjust the contents of our advertisements
            and promotions which might be interesting for you.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Cookies and similar techniques</span>
      <div class="document-section-content">
        <p>
          We use cookie files, web notifications and similar techniques (commonly known as "cookies"), when you use our
          Websites or Applications. A Cookie is a small text file, containing an alphanumerical sequence (letters and
          numbers). Here is where you can find our explanation how we use cookies on each of our Websites or
          Applications and what possibilities you have as a visiting user in terms of our usage of cookie files (our
          Policy regarding cookie files on our Website or in the Application where you're currently reading this Privacy
          Policy).
        </p>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Legal basis for the processing of Personal Data</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            We process personal data only when we have legal basis for it. We mostly use your personal data when:
            <ul>
              <li>
                processing your personal data is necessary to complete an agreement you made with us directly or
                indirectly;
              </li>
              <li>
                it's necessary to complete our legally justified deals (or legally justified deals of a third
                party entity). We make sure that your basic rights and freedoms are not violated by our legally
                justified deals;

              </li>
              <li>
                we use your personal data to meet our legal obligations - for example in making sure that we use
                your personal data to fulfill our obligations in terms of preventing bribery, money laundering
                or financing terrorism.
              </li>
            </ul>
          </li>
          <li>
            We might ask you to give us a clear consent for the processing of your data when we're obligated by the
            currently present regulations.
          </li>
          <li>
            In some countries there might be additional legal basis regarding the processing of personal data. They
            are dependent on active law and on goods and services you are offered.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Protection of Personal Information</span>
      <div class="document-section-content">
        <ul class="document-section-empty">
          <li>We adopt appropriate data collection, storage and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or destruction of your personal information, username,
            password, transaction information and data stored on our website.
          </li>
          <li>
            The security of your personal data is essential to iPago.
          </li>
          <li>
            iPago exercises all legal, technical and organizational resources deemed necessary to ensure the privacy and
            security of your personal data and abides by appropriate obligations and exceptions listed in active
            regulations.
          </li>
          <li>
            Apart from that, iPago also abides by the standards specified by the financial industry in regards to
            payments card information protection. Our company is regularly inspected to ensure the highest level of
            accordance with PCI DSS (Payments Card Information Security Standard Council) standards regarding the
            protection of payments card information.
          </li>
          <li>
            iPago regularly conducts inspections of its own rules regarding collection, storage and processing of your
            personal data, including solutions regarding physical safety to prevent unauthorized distortions, changes,
            loss, usage or unauthorized access to your personal data.
          </li>
          <li>
            iPago implemented procedures regarding the proceedings in cases of potential personal data abuse. We will
            inform you, as well as a regulatory authority of every abuse if we're obligated to.
          </li>
          <li>
            iPago can store your personal data for as long as it's needed to fulfill the purpose of collecting them.
            Storage of data by iPago is determined based on the accordance with legal requirements (statutory or
            contractual), accounting requirements and regarding compatibility reporting. For example, based on the
            accordance with regulations regarding preventing fraud, money laundering and combating corruption and
            financing terrorism.
          </li>
          <li>
            iPago also includes time limits specified in regulations regarding commercial activity or data protection as
            well as other regulations present in various countries in which iPago offers services.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Sharing of Personal Information</span>
      <div class="document-section-content">
        <p>
          We do not sell, trade, or rent users' personal identification information to others. We may share generic
          aggregated demographic information not linked to any personal identification information regarding visitors
          and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
        </p>
        <p>
          We can pass your personal data to external third parties, including:
        </p>
        <ul class="document-section-points">
          <li>
            acceptors, in accordance with agreements (known also as rules and terms of service) we made with them.
            For example, to process a card payment we are obligated to pass client's credit card data to the
            acceptor, whom the payment affects. If you purchase goods or services using our services, we might also
            provide the acceptor with your credit card's billing address to help with processing of an individual
            payment transaction.
          </li>
          <li>
            authorized external financial institutions and bank partners with whom we cooperate in order to create
            and offer products and services. Depending on the payment type chosen by the client, payer or buyer, We
            might pass data to financial institutions which authorize and process various payments for the purpose
            of confirming, authorizing and billing. This means, that your personal data might be collected for this
            purpose by financial institutions that issue means of payment, by financial institutions receiving
            payments, by networks processing payments, by organizations such as Visa, MasterCard.
          </li>
          <li>
            credit agencies and creditors, for the purpose of notifying financial institutions and conducting
            permitted credit ratings and establishing the possibility of granting a loan, strictly to the extent
            permitted by law.
          </li>
          <li>
            service providers or providers we made agreements with regarding providing us help with conducting
            business.
          </li>
          <li>
            companies we plan to take over or other entities by which we might be taken over - in such situations we
            will require that the new, combined entity or the entity taking over follows the rules of this privacy
            policy in regards to your personal data.
          </li>
          <li>
            when it's required by the judicial authorities, government officials, fraud detection agencies or other
            external entities or when we are legally obligated to (for example by a call to testify, court order or
            other similar procedure).
          </li>
          <li>
            international entities we cooperate with in terms of offering and/or developing products and services,
            subject to the agreements of this Policy and in accordance with current regulations.
          </li>
        </ul>
        <p>
          Pago implements various, reasonable organisational and technical solutions to ensure that every external
          entity taking part in processing your personal data uses required technical and organisational resources
          regarding data protection, including required agreements regarding data processing and data transfer when it's
          necessary. If it's mandatory on the basis of the current regulations, we can provide you with a list of
          processing entities and providers if you request it. To do that, contact us via contact@ipagopay.com e-mail
          address.
        </p>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Third Party Websites</span>
      <div class="document-section-content">
        <p>
          Users may find advertising or other content on our website that link to the sites and services of our
          partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content
          or links that appear on these sites and are not responsible for the practices employed by websites linked to
          or from our website. In addition, these sites or services, including their content and links, may be
          constantly changing. These sites and services may have their own privacy policies and customer service
          policies. Browsing and interaction on any other website, including websites which have a link to our website,
          is subject to that website's own terms and policies.
        </p>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Changes to Privacy Policy</span>
      <div class="document-section-content">
        <p>
          We may update our privacy policy from time to time. We will notify you of any changes by posting the new
          privacy policy on the website. You are advised to review this privacy policy periodically for any changes.
        </p>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Consent</span>
      <div class="document-section-content">
        <p>
          By using our website, you hereby consent to our privacy policy and agree to its terms.
        </p>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Contact Us</span>
      <div class="document-section-content">
        <p>
          If you have any questions about this privacy policy, the practices of this website, or your dealings with this
          website, please contact us at contact@ipagopay.com.
        </p>
      </div>
    </div>
    <div class="document-section" style="margin-top: 50px;">
      <strong>
        This privacy policy is based on the Canadian Personal Information Protection and Electronic Documents Act
        (PIPEDA) and the guidelines provided by the Office of the Privacy Commissioner of Canada.
      </strong>
    </div>
  </div>
</template>

<style lang="scss">
.document {
  width: var(--max-width);
  max-width: 100%;
  margin: 50px 0px 50px 0px;
  border-radius: 10px;
  padding: 50px;
  font-weight: 400;
  font-family: "Inter";
  z-index: 10;

  &>.document-title {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 0px 50px 0px;
  }

  &>.document-section {
    display: flex;
    flex-direction: column;

    &>.document-section-title {
      font-size: 18px;
      font-weight: 700;
      margin: 25px 0px;
    }

    &>.document-section-content {
      margin: 0px 0px 0px 45px;
      opacity: 0.8;
    }

    & .document-section-empty {
      text-align: justify;

      &>li+li {
        margin-top: 15px;
      }
    }

    & p {
      margin-bottom: 15px;
    }

    & .document-section-points {
      list-style-type: decimal;
      list-style-position: inside;
      text-align: justify;

      &>li+li {
        margin-top: 15px;
      }

      &>li {
        & ul {
          list-style-type: decimal;
          list-style-position: inside;
          margin: 0px 0px 0px 45px;

          &>li {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>

<script>
import Watermark from '@/components/watermark/Watermark.vue';

export default {
  name: 'PrivacyPage',
  components: { Watermark },
};
</script>