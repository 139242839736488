<template>
  <div class="main-container">
    <Header></Header>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';
import Content from './components/layout/Content.vue';

export default {
  name: 'AppComponent',
  components: {
    Header,
    Content,
    Footer,
  }
}
</script>

<style lang="scss">
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;

  @media (max-width: 700px) {
    padding: 0px;
  }
}
</style>
