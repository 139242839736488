<template>
  <Watermark></Watermark>
  <div class="document">
    <span class="document-title">Terms and Conditions</span>
    <div class="document-section">
      <span class="document-section-title">DEFINITIONS</span>
      <div class="document-section-content">
        <ul class="document-section-empty">
          <li>
            Pago - IPAGO PAYMENTS INC with its seat in 1500 West Georgia BC, Vancouver, V6G 2Z6 Canada.
          </li>
          <li>
            iPago Services - iPago provides financial services and solutions related to payment transactions.
          </li>
          <li>
            API - interface which the User can access, enabling secure online transfer of information and data as
            well
            as triggering and implementation of tasks related to Our Services.
          </li>
          <li>
            Authentication - processes and procedures aimed at identifying the User or checking the validity of the
            payment instrument used by the User in order to use the iPago Services.
          </li>
          <li>
            Business Day - a day other than Saturday and Sunday and any other public holiday in Canada or any other
            country involved in any way in the provision of iPago Services.
          </li>
          <li>
            User - a natural person who holds an account in the iPago system enabling the use of iPago Services.
          </li>
          <li>
            Organisation - a sub-account representing an Acceptant who is not a natural person under the iPago
            Services.
            It may store the Acceptant's funds collected with the Services linked to the sub-account. Organisation
            may
            be created from the User Account level.
          </li>
          <li>
            Authorized Person - A User who is an owner, employee or other person authorised to represent the
            Acceptant
            under the iPago Services. This person has access to the Organisation associated with the Acceptant.
          </li>
          <li>
            Acceptant - an adult natural person, legal person or an organisational unit which is not a legal person
            and
            to which the law grants legal capacity, holding the Organisation's subaccount in the iPago system.
          </li>
          <li>
            Customer - a natural person, a legal person or an organisational unit that is not a legal person and to
            which the law grants legal capacity, using iPago Services as a User or Guest.

          </li>
          <li>
            Guest - a person without a iPago Account who uses the iPago Services made available to non-registered
            Customers.
          </li>
          <li>
            List of Fees - the fee table listing the fees and commissions applicable to the services provided under
            these iPago Terms and Conditions. The Fees List refers to the separate addendum (if any) provided to the
            Acceptant by iPago.
          </li>
          <li>
            Terms and Conditions - this document being the agreement between the Customer and iPago.
          </li>
          <li>
            Payment Instrument - the method and/or personal device offered and/or activated by iPago that enables a
            payment to be made in the iPago system.
          </li>
          <li>
            iPago Account - account available to Users where information about the User may be stored and processed
            under these Terms and Conditions.
          </li>
          <li>
            iPago Application - a program made available for certain mobile devices that may be used to register the
            User with iPago and to access the Services.
          </li>
          <li>
            Payment Order - an instruction given to iPago by the Customer to carry out a transaction.
          </li>
          <li>
            iPago Site - https://ipagopay.com/ website and/or its subdomains.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">GENERAL PROVISIONS</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            These Terms and Conditions determine the use of the Services provided by iPago as well as the
            establishment
            and management of Accounts and Organisations by Users and the relationship of Guests with iPago. The
            Terms
            and Conditions also determine the fees applicable to the use of the Services and the Customer Service
            procedures.
          </li>
          <li>
            All Customers must read these Terms and Conditions. All Users who register a iPago Account and other
            Customers who use the Services without registration accept these Terms and Conditions, including all
            other
            agreements, rules and regulations and their annexes referred to in this document, as well as any updates

          </li>
          <li>
            Customers using the iPago Services must read and accept the regulations of the respective services,
            available at the addresses given above.
          </li>
          <li>
            The Terms and Conditions are available in the English language. The Customer agrees that the version of
            the
            Terms and Conditions provided in English shall be binding and prevail over any other translation into
            other
            languages that may be provided by iPago.
          </li>
          <li>
            iPago is engaged in the provision of payment solutions and services and services generally related to
            the
            financial sector for Customers covered by these Terms and Conditions, in particular online payment
            services,
            creation of accounts used to carry out transactions and collect funds, transfer of funds between
            accounts
            and withdrawals from such accounts.
          </li>
          <li>
            The use of iPago services is not related to opening a bank account within the meaning of the Civil Code
            and
            the Banking Law Act.
          </li>
          <li>
            The products and services provided by iPago may only be used in the manner described in these Terms and
            Conditions and subject to the agreements made with iPago.
          </li>
          <li>
            Any reference to the Terms and Conditions also includes any other supplemental terms and conditions,
            addenda
            or agreements that directly refer to these Terms and Conditions. In the event of any discrepancy between
            the
            special conditions and the Terms and Conditions, the special conditions shall prevail.
          </li>
          <li>
            Users may use the iPago Services and manage or view their Account information by logging into the
            dedicated
            system by performing the necessary Authentication using the iPago Site or the iPago Application and, in
            part, by using the Payment Instruments associated with certain iPago Services.
          </li>
          <li>
            iPago declares that it is the owner of the ipagopay.com website and that it is entitled to all of their
            rights.
          </li>
          <li>
            iPago may add new features to its Services as well as change or modify the existing ones, of which it
            shall
            inform the Customers by means of a communication sent digitally, via email or the iPago service, as long
            as
            the changes concern them.
          </li>
          <li>
            The Customer shall not allow any other person to use its iPago Account or share its account details.
          </li>
          <li>
            iPago is entitled to charge fees for the provision of the Services, in accordance with the List of Fees
            or
            on the basis of other annexes adopted by iPago and the Customer.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">REGISTRATION</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            In order to fully use any of the iPago Services, Customers must accept these Terms and Conditions and
            create
            their iPago Account.
          </li>
          <li>
            The Services are only available to Users who are permanent residents and who, at the time of Account
            creation, are located in a country (or jurisdiction) that is supported by iPago. Otherwise, their
            application to create iPago Account will be rejected.
          </li>
          <li>
            Individuals applying for a iPago Account will need to go through a remote electronic identification
            process.
            This process is further described in the "Verification and Identification - Accounts" section.
          </li>
          <li>
            Legal persons applying for registration of an Organisation will be required to confirm the identity of
            the
            Person or Persons authorised to represent the entity for the purposes of the Organisation, as the
            Organisation being an account of a legal person is not a separate Account, but only a sub-account,
            intended
            to support specific iPago Services, within the iPago Account of a natural person who is the Acceptant's
            Owner or representative.
          </li>
          <li>
            Once an Organisation is created for a legal person, the Account that was used to create the sub-account
            becomes the owner of the Organisation.
          </li>
          <li>
            The Organissation Owner may authorise additional Users to use the Services under the sub-account. The
            Organisation Owner shall be fully responsible for the actions taken by these Authorised Users.
          </li>
          <li>
            iPago may request additional information and documentation from the Acceptant and the above Users to
            confirm
            their identity and authority to act on behalf of the Acceptant.
          </li>
          <li>
            The process of identification and verification of Legal Persons applying for registration of
            Organisations
            under the iPago Services and Eligible Persons is further specified in the section "Verification and
            Identification - Organisations".
          </li>
          <li>
            Each person may create only one iPago account.
          </li>
          <li>
            An Acceptant can only be associated with one organisation.
          </li>
          <li>
            By creating an Account, the User declares that they have the capacity to enter into legal transactions,
            to
            hold funds within the balances and to assume the obligations provided for in the Terms and Conditions.
          </li>
          <li>
            Additionally, the User certifies that the data provided during registration is up-to-date and correct.
          </li>
          <li>
            Users are obliged to comply with these Terms and Conditions for the entire duration of their membership.
            Failure to comply with the Terms and Conditions may result in suspension or closure of the Account.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">GUESTS</span>
      <div class="document-section-content">
        <p>You might receive marketing information from iPago, if:</p>
        <ul class="document-section-points">
          <li>
            The use of certain Services or operations within particular Services is available to Guest.
          </li>
          <li>
            A Guest shall be deemed to be a person using the iPago Services or certain functions of the iPago
            Services
          </li>
          <li>
            made available to persons without a iPago Account.
          </li>
          <li>
            The Guest should provide iPago with all information, data and documents requested by iPago.
          </li>
          <li>
            iPago may request additional identification and verification of the Guest.
          </li>
          <li>
            iPago has the right to restrict access to certain or all iPago Services.
          </li>
          <li>
            iPago may require the Guest to create a iPago Account in order to complete the process of iPago
            Services.
          </li>
          <li>
            When a Guest creates a iPago Account, they become a User.
          </li>
          <li>
            Any actions associated with the Guest may be linked to the iPago Account at the time of the
            registration.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">VERIFICATION AND IDENTIFICATION - ACCOUNTS</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            When creating an Account one must provide:
            <ul>
              <li>
                Phone number
              </li>
            </ul>
          </li>
          <li>
            In order to use certain Paymenterio Services it may be necessary to provide additional information,
            including but not limited to:
            <ul>
              <li>
                First name
              </li>
              <li>
                Surname
              </li>
              <li>
                ID number
              </li>
              <li>
                Date of birth
              </li>
              <li>
                Place of birth
              </li>
              <li>
                Address of residence
              </li>
              <li>
                Nationality
              </li>
            </ul>
          </li>
          <li>
            Applicants for iPago Account may be required to provide scans of identity documents.
          </li>
          <li>
            At any time, before or after the creation of an Account, iPago reserves the right to request additional
            documents and information from the User owning the Account in order to comply with iPago 's obligations
            under applicable law.
          </li>
          <li>
            All information and documents provided by Users must be up to date and correct.
          </li>
          <li>
            iPago may require English translations of the documents mentioned in this section. In this case, the
            User is obliged to send translations of those documents as soon as possible.
          </li>
          <li>
            iPago has the right to refuse to register a new Account without giving reasons. The refusal of
            registration will always be based on compelling reasons, which iPago does not have to or is not entitled
            to disclose.
          </li>
          <li>
            iPago may process any information provided by the Customer, including information contained in identity
            documents, and make copies thereof.
          </li>
          <li>
            iPago has the right to request any information listed in the internal procedures and in particular the
            Internal Procedure on Anti-Money Laundering and Terrorist Financing. The Customer shall be obliged to
            provide all documents requested by iPago.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">Legal basis for the processing of Personal Data</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            We process personal data only when we have legal basis for it. We mostly use your personal data when:
            <ul>
              <li>
                processing your personal data is necessary to complete an agreement you made with us directly or
                indirectly;
              </li>
              <li>
                it's necessary to complete our legally justified deals (or legally justified deals of a third
                party entity). We make sure that your basic rights and freedoms are not violated by our legally
                justified deals;

              </li>
              <li>
                we use your personal data to meet our legal obligations - for example in making sure that we use
                your personal data to fulfill our obligations in terms of preventing bribery, money laundering
                or financing terrorism.
              </li>
            </ul>
          </li>
          <li>
            We might ask you to give us a clear consent for the processing of your data when we're obligated by the
            currently present regulations.
          </li>
          <li>
            In some countries there might be additional legal basis regarding the processing of personal data. They
            are dependent on active law and on goods and services you are offered.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">VERIFICATION AND IDENTIFICATION - ORGANISATIONS</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            When setting up the Organisation one has to provide:
            <ul>
              <li>
                The iPago account owned by the Organisation in the iPago system.
              </li>
              <li>
                In the case of an individual:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Nationality
                  </li>
                  <li>
                    ID Number
                  </li>
                  <li>
                    Date of birth
                  </li>
                  <li>
                    Place of birth
                  </li>
                  <li>
                    Series and number of the identity document of the person
                  </li>
                  <li>
                    Address of residence
                  </li>
                  <li>
                    Company name
                  </li>
                  <li>
                    Tax identification number
                  </li>
                  <li>
                    Address of main place of business
                  </li>
                  <li>
                    Phone number
                  </li>
                </ul>
              </li>
              <li>
                In case of a legal person or organisational unit without legal personality:
                <ul>
                  <li>
                    Name (of the company)
                  </li>
                  <li>
                    Organisational form
                  </li>
                  <li>
                    Registered or business address
                  </li>
                  <li>
                    Tax Identification Number
                  </li>
                  <li>
                    Country of registration
                  </li>
                  <li>
                    Commercial register and the registration number and date
                  </li>
                </ul>
              </li>
              <li>
                Data of individuals authorised to represent the organisation:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    ID Number
                  </li>
                  <li>
                    Date of birth
                  </li>
                  <li>
                    Nationality
                  </li>
                  <li>
                    Country of birth
                  </li>
                  <li>
                    Telephone number
                  </li>
                </ul>
              </li>
              <li>
                Actual beneficiaries:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Nationality
                  </li>
                  <li>
                    ID Number
                  </li>
                  <li>
                    Date of birth
                  </li>
                  <li>
                    Series and number of identity document
                  </li>
                  <li>
                    Address of residence
                  </li>
                  <li>
                    Place of birth
                  </li>
                  <li>
                    Phone number
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Depending on the Services used, iPago may request additional information, including but not
            limited to:
            <ul>
              <li>
                Details of main contact person:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    E-mail address
                  </li>
                  <li>
                    Telephone number
                  </li>
                </ul>
              </li>
              <li>
                Details of the contact person for customer service:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    E-mail address
                  </li>
                  <li>
                    Telephone number
                  </li>
                </ul>
              </li>
              <li>
                Details of the financial contact person:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    E-mail address
                  </li>
                  <li>
                    Telephone number
                  </li>
                </ul>
              </li>
              <li>
                Details of the AML contact person:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    E-mail address
                  </li>
                  <li>
                    Telephone number
                  </li>
                </ul>
              </li>
              <li>
                Details of the contact person for technical matters:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    E-mail address
                  </li>
                  <li>
                    Telephone number
                  </li>
                  <li>
                    Mobile phone number
                  </li>
                </ul>
              </li>
              <li>
                Email address to which invoices and other documents are to be sent
              </li>
              <li>
                Information regarding bank accounts, including:
                <ul>
                  <li>
                    Name of the bank
                  </li>
                  <li>
                    Account holder
                  </li>
                  <li>
                    Account number
                  </li>
                  <li>
                    Swift/BIC
                  </li>
                  <li>
                    IBAN
                  </li>
                  <li>
                    Sort code
                  </li>
                </ul>
              </li>
              <li>
                Method of customer verification in the Acceptant's systems
              </li>
              <li>
                Type of activity
              </li>
              <li>
                Directors:
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    Date of birth
                  </li>
                  <li>
                    Nationality
                  </li>
                  <li>
                    Country of birth
                  </li>
                  <li>
                    Representative power
                  </li>
                </ul>
              </li>
              <li>
                Shareholders
                <ul>
                  <li>
                    First name
                  </li>
                  <li>
                    Surname
                  </li>
                  <li>
                    Position
                  </li>
                  <li>
                    Date of birth
                  </li>
                  <li>
                    Nationality
                  </li>
                  <li>
                    Shareholding %
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            iPago may request from the legal entities registering within the iPago Organisation an official document
            showing a list of its shareholders and/or beneficial owners.
          </li>
          <li>
            At any time, before or after the establishment of the Organisation, iPago reserves the right to request
            additional documents and information relating to the Acceptant, owner or other persons associated with
            the
            Organisation from the User owning the Organisation in order to comply with iPago's obligations under
            applicable law.
          </li>
          <li>
            At any time, before or after the establishment of the Organisation, iPago reserves the right to request
            additional documents and information about the Acceptant from the User who owns the Organisation and all
            Authorised Persons in order to comply with iPago's obligations under applicable law.
          </li>
          <li>
            iPago has the right to refuse to register a new Organisation without stating reasons. Refusal of
            registration will always be based on compelling reasons, which iPago does not have to or is not entitled
            to
            disclose.
          </li>
          <li>
            iPago may process any information provided by the Customer, including information contained in the
            identity
            documents of the Customer, Authorised Persons and persons associated with the Acceptant (including
            representatives, beneficial owners of directors and managers) and make copies thereof.
          </li>
          <li>
            iPago has the right to request any information listed in the internal procedures, in particular the
            Internal
            Procedure for Anti-Money Laundering and Terrorist Financing. The Customer shall be obliged to provide
            all
            documents requested by iPago.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">ADDITIONAL VERIFICATION</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            The use of certain iPago Services may require additional verification of the Customer, User or
            Organisation.
          </li>
          <li>
            The Customer shall provide iPago with all information necessary for the provision of the Services.
          </li>
          <li>
            iPago may re-verify the Customer, User or Organisation at any time.
          </li>
          <li>
            Re-verification may take place in full or in part.
          </li>
          <li>
            Refusal to provide iPago with information, documents or data may result in suspension or closure of the
            Account or Organisation or otherwise preventing the Customer from using iPago Services.
          </li>
          <li>
            The iPago may request the Customer to make a verification transfer in the amount of 1 to 10 EUR or its
            equivalent in another currency, to the address given by iPago.
          </li>
          <li>
            The verification transfer should be made from the bank account indicated during verification.
          </li>
          <li>
            After the verification process, an invoice will be issued for the amount sent during the verification of
            the
            bank account.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">SECURITY</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            The Customer shall protect the login details, passwords, access codes, iPago Cards, Payment Instruments,
            devices/mobile numbers recognized by iPago and any other means of Authentication from theft or
            unauthorised
            access by third parties. The Customer should ensure that all electronic devices on which it uses the
            iPago
            Services have the appropriate up-to-date software and hardware installed and running to ensure device
            and
            network security (including firewalls, anti-virus software).
          </li>
          <li>
            The Customer may only use valid and secure domains and software to use the iPago Services or to store
            and
            enter any authentication data.
          </li>
          <li>
            The Customer should immediately notify iPago if they have a reasonable suspicion that any of the User's
            login details, passwords, iPago Cards or devices, telephone numbers recognized by iPago have been
            stolen,
            lost, misappropriated or otherwise exposed to third parties or if the Customer becomes aware of any
            other
            suspicious activity or unauthorised login.
          </li>
          <li>
            The Organisation Owner shall be fully responsible for controlling the access and permissions of
            Authorised
            Persons, informing iPago of changes in representation or changes concerning Authorised Persons. The
            Organisation Owner and the Acceptant are responsible for the actions taken by Authorised Persons or
            other
            entities holding credentials, including logins and passwords.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">REFUNDS</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            The Customer may request cancellation of unauthorised or incorrectly performed Services in accordance
            with
            the Terms and Conditions of the individual iPago Services.
          </li>
          <li>
            The terms of refunds are described in the Terms and Conditions of specific iPago Services.
          </li>
          <li>
            The procedures for claiming refunds are described in the Terms and Conditions of specific iPago
            Service.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">EXCLUSION OF LIABILITY</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            iPago is not be liable for not completing or completing Services incorrectly due to force majeure.
          </li>
          <li>
            Force majeure shall be understood as external events beyond the control of the iPago and impossible to
            foresee, such as in particular: war, fire, epidemics, flood, communication blockages of supra-regional
            nature, social disasters or catastrophes of buildings or structures.
          </li>
          <li>
            In the event of force majeure, iPago will inform the parties immediately if it is unable to fulfil its
            obligations.
          </li>
          <li>
            iPago is not liable for Services not being completed or being completed incorrectly by any of its
            partners.
          </li>
          <li>
            iPago is not liable for any Services not being completed or being completed incorrectly due to incorrect
            data provided by the Customer.
          </li>
        </ul>
      </div>
    </div>
    <div class="document-section">
      <span class="document-section-title">FINAL PROVISIONS</span>
      <div class="document-section-content">
        <ul class="document-section-points">
          <li>
            iPago reserves the right to amend the Terms and Conditions at any time. The amendments shall be
            effective
            from the date of publication of the Terms and Conditions on the iPago website.
          </li>
          <li>
            iPago will notify Customers by sending an e-mail to the addresses provided during registration of the
            intended change to the Terms and Conditions 7 (seven) days prior to the intended update.
          </li>
          <li>
            If within 7 (seven) days from the date of delivery of the message indicated in the paragraph above the
            Customer does not submit a notice of termination, the changes shall be deemed to have been accepted and
            shall be effective from the day following the expiry of this period.
          </li>
          <li>
            Termination should be requested by notifying iPago at the mailing address provided in the email.
          </li>
          <li>
            Amendments to other documents to which these iPago Terms and Conditions refer to are not subject to the
            obligations referred to in clauses 1 and 2 of this section unless this is stated directly in those
            documents.
          </li>
          <li>
            The law applicable to the provision of services by electronic means is Canadian law.
          </li>
          <li>
            The competent court for all disputes arising from these Terms and Conditions shall be the court with
            jurisdiction over iPago 's registered office.
          </li>
          <li>
            The last changes to this document was made on December 20, 2022.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.document {
  width: var(--max-width);
  max-width: 100%;
  margin: 50px 0px 50px 0px;
  border-radius: 10px;
  padding: 50px;
  font-weight: 400;
  font-family: "Inter";
  z-index: 10;

  &>.document-title {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 0px 50px 0px;
  }

  &>.document-section {
    display: flex;
    flex-direction: column;

    &>.document-section-title {
      font-size: 18px;
      font-weight: 700;
      margin: 25px 0px;
    }

    &>.document-section-content {
      margin: 0px 0px 0px 45px;
      opacity: 0.8;
    }

    & .document-section-empty {
      text-align: justify;

      &>li+li {
        margin-top: 15px;
      }
    }

    & p {
      margin-bottom: 15px;
    }

    & .document-section-points {
      list-style-type: decimal;
      list-style-position: inside;
      text-align: justify;

      &>li+li {
        margin-top: 15px;
      }

      &>li {
        & ul {
          list-style-type: decimal;
          list-style-position: inside;
          margin: 0px 0px 0px 45px;

          &>li {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>

<script>
import Watermark from '@/components/watermark/Watermark.vue';

export default {
  name: 'PrivacyPage',
  components: { Watermark },
};
</script>