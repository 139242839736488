<template>
    <div class="home-container">
        <div class="scope scope-america">
            <a class="scope-logo" href="https://ipagopay.com" target="_blank">
                <img src="@/../resources/images/logos/logo_dark.svg" />
            </a>
            <span class="scope-continent">
                North America
            </span>
            <a class="scope-country" href="https://ipagopay.com" target="_blank">
                <img src="@/../resources/images/countries/ca.svg" />
                <span>Canada</span>
            </a>
            <span class="scope-since">
                Coming <span>soon</span>
            </span>
        </div>
    </div>
</template>

<style lang="scss">
.home-container {
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 150px;

    &>.scope {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        &::before {
            background: var(--secondary-glow);
            border-radius: 50%;
            width: 480px;
            height: 360px;
            margin-left: -400px;
        }

        &::after {
            background: var(--primary-glow);
            width: 240px;
            height: 180px;
            z-index: -1;
        }

        &::before,
        &::after {
            content: '';
            left: 50%;
            position: absolute;
            filter: blur(45px);
            transform: translateZ(0);
        }

        &>.scope-continent {
            font-size: 24px;
            font-weight: 300;
            text-align: center;
            z-index: 1;
        }

        &>.scope-logo {
            text-align: center;

            &>img {
                max-height: 50px;
                margin: 0px auto;
            }

            margin-bottom: 25px;
        }

        &>.scope-since {
            margin-top: 25px;
            font-size: 16px;
            font-weight: 300;
            text-align: center;

            &>span {
                font-weight: 600;
            }
        }

        &>.scope-country {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 300px;
            width: 100%;
            height: 45px;
            padding: 25px 10px;
            transform: translateZ(0);
            border-radius: var(--border-radius);
            overflow: hidden;
            box-shadow: 0px 2px 8px -1px #0000001a;
            position: relative;

            &>img {
                width: 24px;
                height: 24px;
                border-radius: 180px;
                margin-right: 10px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: -1;
            }

            &::before {
                animation: 6s rotate linear infinite;
                width: 200%;
                height: 200%;
                background: var(--tile-border);
            }

            &::after {
                inset: 0;
                padding: 1px;
                border-radius: var(--border-radius);
                background: linear-gradient(to bottom right,
                        rgba(var(--tile-start-rgb), 1),
                        rgba(var(--tile-end-rgb), 1));
                background-clip: content-box;
            }
        }
    }

    &>.scope-america {}

    &>.scope-europe {
        &::before {
            background: linear-gradient(to bottom right, rgba(1, 255, 39, 0), rgba(1, 255, 18, 0), rgba(1, 255, 115, 0.3));
        }

        &::after {
            background: radial-gradient(rgba(1, 255, 26, 0.4), rgba(1, 255, 18, 0));
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@media (max-width: 960px) {
    .home-container {
        margin: 100px 0px 100px 0px;
        flex-direction: column;
    }
}
</style>

<script>
export default {
    name: 'HomePage',
    components: {},
};
</script>
