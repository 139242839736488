<template>
  <div class="watermark-quarter">
    <img
      src="@/../resources/images/watermark-quarter.svg"
    />
  </div>
</template>

<script>
export default {
  name: 'WatermarkComponent'
}
</script>

<style lang="scss" scoped>
.watermark-quarter img {
  width: 100%;
  max-width: 1210px;
  height: auto;
  position: absolute;
  left: -397px;
  top: 0;
  z-index: 0;
  max-height: 100%;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(0deg) brightness(4%) contrast(97%);
  opacity: 0.4;
}

.fixed {
  position: fixed !important;
}

.watermark-small {
  img {
    width: 100%;
    max-width: 550px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  &.large {
    img {
      max-width: 900px;
    }
  }
}

.watermark-half {
  img {
    width: 100%;
    max-width: 380px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  &.large {
    img {
      max-width: 900px;
    }
  }
}

.watermark-long img {
  width: 100%;
  max-width: 600px;
  height: auto;
  position: absolute;
  right: -10px;
  top: 1000px;
  z-index: -2;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .watermark-quarter img {
    width: 1280px;
    left: -870px;
  }
  .watermark-long img {
    width: 1030px;
    max-width: none;
    right: -44px;
    top: 1200px;
  }
  .watermark-half {
    img {
      width: 100%;
      max-width: 550px;
    }
    &.large {
      img {
        width: 1430px;
        max-width: none;
        top: 175px;
      }
    }
  }
}
</style>
