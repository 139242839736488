<template>
    <div class="app-content">
        <router-view />
    </div>
</template>

<style lang="scss">
.app-content {}
</style>

<script>
export default {
    name: 'ContentLayoutComponent'
}
</script>