import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';

import Home from '../views/Home';
import About from '../views/About';
import Privacy from '../views/Privacy';
import Terms from '../views/Terms';

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/privacy-policy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms',
        component: Terms
    }
];

const router = createRouter({
    history,
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
});

export default router;