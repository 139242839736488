<template>
    <header>
        <router-link to="/" class="back-home" v-if="currentRouteName != 'Home'">
            <h2>
                <span>&lt;&#45;</span> Back to home
            </h2>
        </router-link>

        <span class="header-by">by</span>
        <span class="header-img">
            <router-link to="/">
            <img src="@/../resources/images/logos/logo_dark.svg" />
            </router-link>
        </span>
    </header>
</template>

<style lang="scss">
.back-home {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
    margin-right: auto;

    & span {
        display: inline-block;
        transition: transform 200ms;
    }

    & h2 {
        font-weight: 600;
        font-family: "Inter";
    }

    &:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);

        & span {
            transform: translateX(4px);
        }
    }
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    max-width: var(--max-width);
    width: 100%;
    z-index: 2;
    font-family: var(--font-mono);

    & > .header-by {
        font-size: 13px;
        font-weight: 700;
    }

    & > .header-img {
        margin-left: 15px;
        & img {
            max-width: 100px;
            width: 100px;
        }
    }
}

@media (max-width: 960px) {
    header {
        & > .header-by,
        & > .header-img {
            display: none;
        }
    }
}
</style>

<script>
export default {
    name: 'HeaderLayoutComponent',
    components: {},
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    }
}
</script>